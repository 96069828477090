import MainPage from "../pages";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginPage from "../pages/login";

const AppLayout = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<MainPage hasSearchField={true} />} />
            <Route path="/mode2" element={<MainPage hasSearchField={false} />} />
            <Route path="/login" element={<LoginPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default AppLayout;
