import { ReactComponent as SearchIcon } from "../../assets/search_icon.svg";
import "./index.css";

const TenantSearchInput = ({
  searchQuery,
  setSearchQuery,
}: {
  searchQuery: string;
  setSearchQuery: (s: string) => void;
}) => {
  const handleClickSearch = () => {};

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div className="flex justify-between items-center p-3 w-[610px] h-[64px] search-box gap-10">
      <input
        type="text"
        placeholder="SEARCH"
        className="pl-2 flex-1 bg-transparent text-white focus:outline-none search-input"
        value={searchQuery || ''}
        onChange={handleChange}
      />
      <button type="button" onClick={handleClickSearch}>
        <SearchIcon />
      </button>
    </div>
  );
};

export default TenantSearchInput;
