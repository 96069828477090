import { tenantSelector } from "../../store/tenants/tenantSlice";
import "./index.css";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { changeShowModal } from "../../store/tenants/tenantSlice";
import { ReactComponent as CloseIcon } from "../../assets/close.svg";

const TenantModal = () => {
  const { currentTenant } = useAppSelector(tenantSelector);
  const dispatch = useAppDispatch();

  const setShowModal = (v: boolean) => {
    dispatch(changeShowModal(v));
  };

  return (
    <>
      <div
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed top-0 bottom-0 left-0 right-0 z-50 outline-none focus:outline-none"
        onClick={() => setShowModal(false)}
      >
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-[#141416] outline-none focus:outline-none w-[544px] h-[356px]">
            {/*header*/}
            <div className="flex items-start justify-between p-5 rounded-t relative">
              <button
                className="absolute -top-3 -right-3 bg-[#23262F] p-2 rounded-full border-0 float-right"
                onClick={() => setShowModal(false)}
              >
                {/* <span className="bg-transparent text-[#777E90] h-6 w-6 text-2xl leading-none block outline-none focus:outline-none">
                  x
                </span> */}
                <CloseIcon />
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6 mt-14">
              {currentTenant && currentTenant.name ? (
                <p className="my-4 modal-tenant-title">{currentTenant.name}</p>
              ) : (
                <p className="my-4 modal-tenant-title">{``}</p>
              )}
            </div>
            {/*footer*/}
            <div className="flex items-center justify-center p-6 rounded-b">
              <div className="py-2 px-[19px] rounded-[10px] border border-solid border-[#353945] bg-[#23262F] min-w-[100px] h-10 overflow-hidden">
                {currentTenant && currentTenant.name ? (
                  <p className="tenant-number max-w-[100%]">
                    {currentTenant.tenantNumber}
                  </p>
                ) : (
                  <p className="tenant-number max-w-[100%]">
                    {""}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="opacity-25 fixed  top-0 bottom-0 left-0 right-0 z-40 bg-black"
        onClick={() => setShowModal(false)}
      ></div>
    </>
  );
};

export default TenantModal;
