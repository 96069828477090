import { useState, useEffect, CSSProperties } from "react";

import TenantList from "../TenantList";
import "./index.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import {
  changePageNumber,
  fetchTenants,
  tenantSelector,
} from "../../store/tenants/tenantSlice";
import { countPerPage, countPerList } from "../../constants";
import { HashLoader } from "react-spinners";

const override: CSSProperties = {
  display: "block",
  position: "absolute",
  zIndex: 100,
  margin: "auto",
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  borderColor: "red",
};

const indicatorStyles: CSSProperties = {
  background: "#fff",
  width: 8,
  height: 8,
  display: "inline-block",
  margin: "0 8px",
  borderRadius: "100%",
};

const TenantCard = ({
  searchQuery,
  path,
}: {
  searchQuery: string;
  path: string;
}) => {
  const { tenants, loading, pageCount } = useAppSelector(tenantSelector);
  const dispatch = useAppDispatch();
  const [carouselCount, setCarouselCount] = useState<number>(0);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);

  useEffect(() => {
    let timer = setTimeout(() => {
      console.log("load first page");
      const query = {
        query: searchQuery,
        pageNumber: 0,
        tenant: path === "/mode2" ? 2 : 1,
      };
      setCurrentPageNumber(0);
      dispatch(changePageNumber(0));
      dispatch(fetchTenants(query));
    }, 1000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [searchQuery]);

  useEffect(() => {
    if (tenants.length) {
      const rest = tenants.length % countPerPage;
      const division = Math.floor(tenants.length / countPerPage);
      // console.log(`division => ${division}`)
      if (rest === 0) {
        setCarouselCount(division);
      } else {
        setCarouselCount(division + 1);
      }
    }
  }, [tenants.length]);

  const handleCarouselChange = (index: number, _: any) => {
    if (currentPageNumber < index) {
      setCurrentPageNumber(index);
      dispatch(changePageNumber(index));
      const query = {
        query: searchQuery,
        pageNumber: index,
        tenant: path === "/mode2" ? 2 : 1,
      };
      dispatch(fetchTenants(query));
    }
  };

  const renderIndicator = (
    onClickHandler: any,
    isSelected: boolean,
    index: number,
    label: string
  ) => {
    if (index >= currentPageNumber - 2) {
      if (isSelected) {
        return (
          <li
            style={{ ...indicatorStyles, background: '#36d7b7' }}
            aria-label={`Selected: ${label} ${index + 1}`}
            title={`Selected: ${label} ${index + 1}`}
          />
        );
      }
      return (
        <li
          style={indicatorStyles}
          onClick={onClickHandler}
          onKeyDown={onClickHandler}
          value={index}
          key={index}
          role="button"
          tabIndex={0}
          title={`${label} ${index + 1}`}
          aria-label={`${label} ${index + 1}`}
        />
      );
    }
    return null;
  };

  return (
    <div className="tenant-card w-full h-full">
      {/* <HashLoader
        size={100}
        color={"#36d7b7"}
        loading={loading}
        cssOverride={override}
        aria-label="Loading Spinner"
        data-testid="loader"
      /> */}
      {!!tenants.length && !!carouselCount ? (
        <Carousel
          className="h-full"
          autoPlay={true}
          interval={10000}
          showArrows={false}
          showThumbs={false}
          showStatus={false}
          showIndicators={true}
          swipeable={true}
          stopOnHover={false}
          infiniteLoop={true}
          emulateTouch={true}
          renderIndicator={renderIndicator}
          selectedItem={currentPageNumber}
          onChange={handleCarouselChange}
        >
          {Array.from({ length: carouselCount }).map((_, index) => (
            <div className="py-[60px] grid grid-cols-3 h-full" key={index}>
              <TenantList
                index={0}
                parentIndex={index}
                tenants={tenants
                  .filter(
                    (_, i) =>
                      i >= countPerPage * index &&
                      i < countPerPage * (index + 1)
                  )
                  .filter(
                    (_, j) =>
                      j > countPerList * 0 - 1 && j < countPerList * (0 + 1)
                  )}
              />
              <TenantList
                index={1}
                parentIndex={index}
                tenants={tenants
                  .filter(
                    (_, i) =>
                      i + 1 > countPerPage * index &&
                      i <= countPerPage * (index + 1)
                  )
                  .filter(
                    (_, j) =>
                      j > countPerList * 1 - 1 && j < countPerList * (1 + 1)
                  )}
              />
              <TenantList
                index={2}
                parentIndex={index}
                tenants={tenants
                  .filter(
                    (_, i) =>
                      i + 1 > countPerPage * index &&
                      i <= countPerPage * (index + 1)
                  )
                  .filter(
                    (_, j) =>
                      j > countPerList * 2 - 1 && j < countPerList * (1 + 2)
                  )}
              />
              {/* <TenantList index={1} parentIndex={index} tenants={tenants} /> */}
              {/* <TenantList index={2} parentIndex={index} tenants={tenants} /> */}
            </div>
          ))}
        </Carousel>
      ) : loading ? (
        <></>
      ) : (
        <div className="text-white text-3xl h-9 w-28 no-data-label">
          No Data
        </div>
      )}
    </div>
  );
};

export default TenantCard;
