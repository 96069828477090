import { Tenant } from "../../store/tenants/tenantSlice";
import TenantItem from "../TenantItem";
import clsx from "clsx";

const TenantList = ({ index, tenants } : { index: number, parentIndex?: number, tenants: Tenant[]}) => {

  return (
    <div className={clsx(`w-full h-full border-[#777E90] border-opacity-30 pl-4 pr-4`, index === 2 ? 'border-none pr-0' : 'border-r', index === 0 ? '' : 'pl-4' )}>
      {tenants.map((t, i) => (
        <TenantItem key={i} tenant={t} />
      ))}
    </div>
  );
}

export default TenantList;