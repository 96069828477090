export const SITE_ACCESS_TOKEN = "dstv_token";
export const SITE_REFRESH_TOKEN = "dstv_refresh_token";
export const localStorageAvailable = () => {
  try {
    // Incognito mode might reject access to the localStorage for security reasons.
    // window isn't defined on Node.js
    // https://stackoverflow.com/questions/16427636/check-if-localstorage-is-available
    const key = "__some_random_key_you_are_not_going_to_use__";

    window.localStorage.setItem(key, key);
    window.localStorage.removeItem(key);

    return true;
  } catch (err) {
    return false;
  }
};

export const saveTokens = (
  accessToken: string | null,
  refreshToken?: string | null
) => {
  if (accessToken) {
    localStorage.setItem(SITE_ACCESS_TOKEN, accessToken);
  } else {
    localStorage.removeItem(SITE_ACCESS_TOKEN);
  }

  if (refreshToken) {
    localStorage.setItem(SITE_REFRESH_TOKEN, refreshToken);
  } else {
    localStorage.removeItem(SITE_REFRESH_TOKEN);
  }
};

export const getToken = () => {
  if (localStorageAvailable()) {
    return localStorage.getItem(SITE_ACCESS_TOKEN);
  }
  return null;
};

export const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  // TODO: should enable in production
  return decoded.exp > currentTime;
};

function jwtDecode(token: string) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join("")
  );

  return JSON.parse(jsonPayload);
}

