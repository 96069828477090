import { useState, useEffect } from "react";
import "./index.css";
import CompanyLogo from "../assets/logo-company.svg";
import TenantCard from "../components/TenantCard";
import TenantSearchInput from "../components/TenantSearchInput";
import TenantModal from "../components/TenantModal";
import { useAppSelector } from "../store/hook";
import { tenantSelector } from "../store/tenants/tenantSlice";
import { userSelector } from "../store/tenants/userSlice";
import { useLocation } from "react-router-dom";
import { getToken, isValidToken } from "../store/localstorage";

const MainPage = ({ hasSearchField }: { hasSearchField: boolean }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const { showModal } = useAppSelector(tenantSelector);
  const { pathname } = useLocation();

  useEffect(() => {
    const token = getToken();
    // if (!token) {
    //   window.location.href = "/login";
    // }
    // if (!isValidToken(token!)) {
    //   window.location.href = "/login";
    // }
  }, []);

  return (
    <>
      <div className="app-container relative overflow-hidden">
        {/* <div className="left-circle"></div> */}
        {/* <div className="right-circle"></div> */}
        <div className="w-full h-full blur-overlay px-5 pb-[50px] flex flex-col gap-8">
          <TenantCard searchQuery={searchQuery} path={pathname}/>
          <div className="flex justify-between items-center">
            <div>
              <a href="https://vitejs.dev" target="_blank" rel="noreferrer">
                <img src={CompanyLogo} className="logo" alt="company logo" />
              </a>
            </div>
            {hasSearchField ? (
              <div>
                <TenantSearchInput
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {showModal && <TenantModal />}
    </>
  );
};

export default MainPage;
