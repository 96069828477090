import { Tenant } from "../../store/tenants/tenantSlice";
import "./index.css";
import { useAppDispatch } from "../../store/hook";
import { setCurrentTenant } from "../../store/tenants/tenantSlice";
const TenantItem = ({ tenant }: { tenant: Tenant }) => {
  const dispatch = useAppDispatch();
  const handleClick = () => {
    console.log("clicked");
    dispatch(setCurrentTenant(tenant));
  };

  return (
    <>
      <div
        className="py-4 border-b border-[#777E90] border-opacity-30 w-full flex justify-between items-center gap-10 cursor-pointer"
        onClick={handleClick}
      >
        <p className="tenant-title text-left truncate">{tenant.name}</p>
        <div className="py-2 px-[19px] rounded-[10px] border border-solid border-[#353945] bg-[#23262F] min-w-[100px] h-10 overflow-hidden">
          <p className="tenant-number max-w-[100%]">{tenant.tenantNumber}</p>
        </div>
      </div>
    </>
  );
};

export default TenantItem;
