import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { saveTokens } from "../localstorage";

const API_BASE_URL = "https://meydanfzdirectory.com";
// const API_BASE_URL = "http://45.142.215.48:4000";
// const API_BASE_URL = "http://localhost:4000";

const initialState = {
  loading: false,
  error: false,
  user: null,
  otp: null,
};

export const login = createAsyncThunk(
  "user/login",
  async (data: { email: string; otp: string }) => {
    const res = fetch(`${API_BASE_URL}/api/auth/otp-login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((_data) => _data.json());

    return res;
  }
);

export const sendOTP = createAsyncThunk(
  "user/sendOTP",
  async (data: { email: string }) => {
    const res = fetch(`${API_BASE_URL}/api/auth/send-otp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((_data) => _data.json());

    return res;
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setOtp: (state, action) => {
      state.otp = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
      console.log(action.payload);
      saveTokens(action.payload.access_token, action.payload.refresh_token);
    });
    builder.addCase(login.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(sendOTP.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(sendOTP.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(sendOTP.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });

  },
});

export const { setUser, setOtp } = userSlice.actions;
export const userSelector = (state: RootState) => state.userReducer;
export default userSlice.reducer;