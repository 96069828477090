import AppLayout from './layout/AppLayout'
function App() {

  return (
    <>
      <AppLayout />
    </>
  )
}

export default App
