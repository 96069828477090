import { configureStore } from '@reduxjs/toolkit';
import tenantReducer from './tenants/tenantSlice';
import userReducer from './tenants/userSlice';
export const store = configureStore({
  reducer: {
    // our reducers goes here
    tenantReducer,
    userReducer
  },
});
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;