import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { countPerPage } from "../../constants";

// const API_BASE_URL = "";
const API_BASE_URL = "https://meydanfzdirectory.com";
// const API_BASE_URL = "http://45.142.215.48:4000";
// const API_BASE_URL = "http://localhost:4000";
export interface Tenant {
  name: string;
  tenantNumber: string;
  id: string;
}

type TenantStateType = {
  tenants: Tenant[];
  loading: boolean;
  pageCount: number;
  currentPageNumber: number;
  currentTenant: Tenant | null;
  showModal: boolean;
};

const initialState: TenantStateType = {
  tenants: [],
  loading: false,
  pageCount: 0,
  currentPageNumber: 0,
  currentTenant: null,
  showModal: false,
};



export const fetchTenants = createAsyncThunk(
  "tenants/fetchTenants",
  async (data: { query: string; pageNumber: number, tenant: number }) => {
    const { query, pageNumber,tenant } = data;
    const res = fetch(
      `${API_BASE_URL}/api/tenants?limit=${countPerPage}&query=${query}&from=${pageNumber}&tenant=${tenant}`
    ).then((_data) => _data.json());

    return res;
  }
);

export const tenantSlice = createSlice({
  name: "tenants",
  initialState,
  reducers: {
    loadTenants: (state, action: PayloadAction<Tenant>) => {
      state.tenants = [...state.tenants, action.payload];
    },
    changePageNumber: (state, action) => {
      state.currentPageNumber = action.payload;
    },
    setCurrentTenant: (state, action) => {
      state.currentTenant = action.payload;
      if (action.payload) {
        state.showModal = true;
      }
    },
    changeShowModal: (state, action) => {
      state.showModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTenants.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchTenants.fulfilled, (state, action) => {
      state.loading = false;
      if (state.currentPageNumber === 0) {
        state.tenants = [...action.payload.data];
      } else {
        state.tenants = [...state.tenants, ...action.payload.data];
      }
      state.pageCount = action.payload.count;
    });
    builder.addCase(fetchTenants.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  loadTenants,
  changePageNumber,
  setCurrentTenant,
  changeShowModal,
} = tenantSlice.actions;
export const tenantSelector = (state: RootState) => state.tenantReducer;
export default tenantSlice.reducer;
