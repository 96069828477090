import React, { useState, useEffect } from "react";
import {
  userSelector,
  sendOTP,
  setOtp,
  login,
} from "../store/tenants/userSlice";
import { useAppSelector, useAppDispatch } from "../store/hook";

const LoginPage = () => {
  const [inputs, setInputs] = useState({
    email: "",
    otp: "",
  });
  const [otpRequested, setOtpRequested] = useState(false);
  const [counter, setCounter] = useState(60);
  const { user, loading, error } = useAppSelector(userSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (user) {
      window.location.href = "/";
    }
  }, [user]);

  useEffect(() => {
    if (otpRequested) {
      // timer for 60 seconds
      const timer = setTimeout(() => {
        setOtpRequested(false);
      }, 60000);
      // decrease counter value every second
      const counterTimer = setInterval(() => {
        if (counter > 0) setCounter((prev) => prev - 1);
        else setCounter(60);
      }, 1000);
      return () => {
        clearTimeout(timer);
        clearInterval(counterTimer);
      };
    }
  }, [otpRequested]);

  const handleChangeInputs = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const handleClickSendCode = () => {
    console.log("handleClickSendCode");
    if (inputs.email === "") {
      alert("Please enter your email");
    } else {
      setOtpRequested(true);
      dispatch(sendOTP({ email: inputs.email }));
    }
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("handleSubmit");
    if (inputs.otp === "") {
      alert("Please enter your OTP");
    } else {
      dispatch(login({ email: inputs.email, otp: inputs.otp }));
    }
  };
  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <a
          href="/"
          className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
        >
          <img
            className="w-8 h-8 mr-2"
            src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/logo.svg"
            alt="logo"
          />
          DSTV
        </a>
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Sign in to your account
            </h1>
            <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
              <div className="flex gap-2 w-full justify-between items-end">
                <div className="w-full">
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Your email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={inputs.email}
                    className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="name@company.com"
                    required
                    onChange={handleChangeInputs}
                  />
                </div>
                <div>
                  <button
                    type="button"
                    disabled={otpRequested}
                    className="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 disabled:bg-gray-400 disabled:hover:bg-gray-400"
                    onClick={handleClickSendCode}
                  >
                    {otpRequested ? "Resend in" : "Send OTP"}
                  </button>
                  {/* show counter if otp requested */}
                  {otpRequested && (
                    <span className="text-gray-400 text-sm">({counter}s)</span>
                  )}
                </div>
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  OTP
                </label>
                <input
                  type="text"
                  name="otp"
                  id="password"
                  placeholder="45678"
                  value={inputs.otp}
                  className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                  onChange={handleChangeInputs}
                />
              </div>
              <button
                type="submit"
                className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Sign in
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};
export default LoginPage;
